<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-row>
          <v-col cols="12" md="12">
            <p
              class="title main-color"
              style="text-align: left"
              v-if="grade_name"
            >
              {{ $i18n.t("Assign Exams") }} : {{ grade_name }}
            </p>
            <p class="title" style="text-align: left" v-if="quarter_name">
              {{ quarter_name }}
            </p>
          </v-col>
        </v-row>

        <!-- Body -->
        <v-row>
          <v-col md="12" class="" v-for="subject in subjects" :key="subject">
            <v-card class="card-container">
              <v-card-text style="padding: 15px 20px">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col md="8" cols="12">
                          <h3 class="title-panel">
                            {{ subject.subject_name }}
                          </h3>
                        </v-col>
                        <v-col md="3" cols="12"> </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr style="background: #fafafa">
                              <th
                                class="text-left bold"
                                style="font-size: 14px !important"
                              >
                                {{ $t("Qualifier") }}
                              </th>
                              <th
                                class="text-left bold"
                                style="font-size: 14px !important"
                              >
                                {{ $t("Assign Progress") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                item, index2
                              ) in subject.subject_qualifiers"
                              :key="index2"
                            >
                              <td style="font-size: small">
                                <v-btn
                                  v-if="item"
                                  class="main-color"
                                  :title="$t('Manage')"
                                  style="
                                    background-color: transparent;
                                    box-shadow: none !important;
                                    text-decoration: underline;
                                    font-size: small;
                                  "
                                  @click="subjectQualifierExamAssignment(item.subject_qualifier_quarter_id)"
                                >
                                  {{ item.qualifier_name }}
                                </v-btn>
                              </td>
                              <td style="font-size: small"></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ActivityExamAssignmentSubjects",

  data() {
    return {
      quarter_id: "",
      academic_grade_id: "",
      subject_id: "",
      subjects: [],
      grade_name: "",
      quarter_name: "",
    };
  },
  watch: {},
  methods: {
    subjectQualifierExamAssignment(subject_qualifier_quarter_id) {
      
      this.$router.replace(
        {
          path:
            "/quarter/grade/subject/qualifier/exam-assignment/" +
            this.academic_grade_id +
            "/" +
            subject_qualifier_quarter_id,
        },
        () => {}
      );
    },
    getAllData() {
      axios
        .get(
          this.getApiUrl +
            "/performance/getGradeSubjectQualifiers/" +
            this.quarter_id +
            "/" +
            this.subject_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.subjects = [];
          this.grade_name = response.data.data.grade_name;
          this.academic_grade_id = response.data.data.academic_grade_id;
          this.quarter_name = response.data.data.quarter_name;
          this.subjects = response.data.data.subjects;
        });
    },
  },
  mounted() {
    this.subject_id = this.$router.currentRoute.params.subject_id;
    this.quarter_id = this.$router.currentRoute.params.quarter_id;
    this.getAllData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// end search input
.title-page {
  margin-top: 15px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.card-container {
  max-width: 80%;
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
}
.card-container .actions {
  position: absolute;
  top: -39px;
  right: 22px;
}
.card-container .actions i,
.card-container .actions span {
  cursor: pointer;
}
.card-container .actions span {
  margin-left: 5px;
}

.title-qualifire .divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.q-box-title {
  font-size: 29px;
  position: relative;
}

.q-box-title h2 {
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.title-page {
  margin-top: 15px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eef3ff;
  margin-bottom: 33px;
}
.v-expansion-panel-content {
  background-color: #f7f7f7;
}
.title-panel {
  color: rgb(114, 151, 255);
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
.details {
  margin-top: 6%;
  overflow: hidden;
}
.details span {
  color: #8d8a8a;
  font-size: 17px;
  font-weight: 700;
}
.details span.passing {
  float: right;
}
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
